import { memo, useState } from 'react';
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';

import { Link } from 'src/navigation/components';
import { CONTAINER_PADDING_H_MOBILE, ifWeb, palette, typography } from 'src/styles';

import { MarkdownContent } from './MarkdownContent/MarkdownContent';
import { Pressable } from './Pressable';
import { StyledText } from './StyledText';

interface Props {
  title: string;
  linkProps?: React.ComponentProps<typeof Link>;
  onPress?(): void;
  rightColumn?: React.ReactNode;
  topBorder?: boolean;
  bottomBorder?: boolean;
  sideBorder?: boolean;
  roundedTopBorder?: boolean;
  roundedBottomBorder?: boolean;
  style?: StyleProp<ViewStyle>;
  textHoveredStyle?: StyleProp<TextStyle>;
  textStyle?: StyleProp<TextStyle>;
  testID?: string;
  isNew?: boolean;
  additionalDescription?: string;
}

export const ListItem = memo<Props>(
  ({
    title,
    linkProps,
    onPress,
    rightColumn,
    topBorder,
    bottomBorder = true,
    sideBorder,
    roundedTopBorder,
    roundedBottomBorder,
    style,
    textHoveredStyle,
    textStyle,
    testID,
    isNew,
    additionalDescription,
  }) => {
    const [isHovered, setIsHovered] = useState(false);

    const content = (
      <View
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={[
          styles.wrapper,
          bottomBorder && styles.wrapperBottomBorder,
          topBorder && styles.wrapperTopBorder,
          sideBorder && styles.wrapperSideBorder,
          roundedTopBorder && styles.roundedTopBorder,
          roundedBottomBorder && styles.roundedBottomBorder,
          style,
        ]}
        testID={testID}
      >
        <View style={[styles.titleWrapper, styles.flexWrapper]}>
          {isNew && <View style={styles.newContentIndicator} />}
          <View style={styles.textWrapper}>
            <MarkdownContent
              style={[typography.body3SemiBold, styles.title, textStyle, isHovered && textHoveredStyle]}
              inline
              limited
            >
              {title}
            </MarkdownContent>
            {!!additionalDescription && (
              <StyledText style={styles.description}>{additionalDescription}</StyledText>
            )}
          </View>
        </View>
        {rightColumn}
      </View>
    );

    return linkProps ? (
      <Link {...linkProps} onPress={onPress || linkProps.onPress}>
        {content}
      </Link>
    ) : onPress ? (
      <Pressable onPress={onPress}>{content}</Pressable>
    ) : (
      content
    );
  },
);

const styles = StyleSheet.create({
  wrapper: {
    paddingVertical: 12,
    borderColor: palette.grey2,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
  },
  wrapperBottomBorder: {
    borderBottomWidth: 1,
  },
  wrapperTopBorder: {
    borderTopWidth: 1,
  },
  wrapperSideBorder: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  roundedTopBorder: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  roundedBottomBorder: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  textWrapper: {
    flexDirection: 'row',
    alignItems: 'baseline',
    flexWrap: 'wrap',
    flex: 1,
  },
  title: {
    flexShrink: 1,
    marginRight: 10,
    lineHeight: 20,
    color: palette.navy,
    ...ifWeb({
      wordBreak: 'break-word',
    }),
  },
  titleWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexWrapper: {
    flex: 1,
  },
  newContentIndicator: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: palette.blue,
    marginRight: 8,
  },
  description: {
    lineHeight: 20,
  },
});
