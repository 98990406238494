import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Platform, Keyboard } from 'react-native';

import { BaseButton, InputItem, InputItemRef, StyledText, Switch, TextButton } from 'src/components';
import { getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { isWeb } from 'src/helpers';
import { Yup } from 'src/helpers/validation';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { typography, palette } from 'src/styles';

import { useSignIn } from '../hooks';
import type { SignInErrors, SignInFormData } from '../types';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t('validation:enterValidEmail'))
    .required(i18n.t('validation:pleaseEnterEmail')),
  password: Yup.string().required(i18n.t('validation:pleaseEnterPassword')),
});

interface Props {
  email?: string;
  password?: string;
}

export const SignInForm: React.FC<Props> = ({ email, password }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [commonError, setCommonError] = useState('');

  const initialState: SignInFormData = {
    email: email || '',
    password: password || '',
    remember: !isWeb && !!(email && password),
  };
  const focusEmailInputByDefault = !email;

  const { mutate: signIn, isPending } = useSignIn();

  const {
    handleChange,
    handleBlur,
    values,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setErrors,
    handleReset: resetForm,
  } = useFormik({
    initialValues: initialState,
    onSubmit: (values) =>
      signIn(values, {
        onError: (error) => {
          const { email, password, detail } = getMessagesFromErrorResponse<SignInFormData>(error);
          setErrors({ email, password });
          setCommonError(detail ?? '');

          const { expired } = (error?.response?.data as SignInErrors) || {};
          if (expired) {
            resetForm(undefined);
          }
        },
      }),
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: isSubmitted,
    enableReinitialize: true,
  });
  const { isSmallMobile } = useDeviceInfo();
  const emailInputRef = useRef<InputItemRef>(null);

  useEffect(() => {
    if (focusEmailInputByDefault) {
      const timeout = setTimeout(() => {
        emailInputRef.current?.focus();
      }, 600);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [focusEmailInputByDefault]);

  const onSubmit = () => {
    if (!isWeb) {
      Keyboard.dismiss();
    }
    setIsSubmitted(true);
    handleSubmit();
  };

  const handleEditingSubmit = isWeb ? onSubmit : undefined;

  const handleEmailChange = (value: string) => {
    handleChange('email')(value.replace(/\s/g, ''));
  };

  return (
    <>
      <InputItem
        onChangeText={handleEmailChange}
        onBlur={handleBlur('email')}
        value={values.email}
        error={errors.email}
        touched={touched.email}
        label={i18n.t('email')}
        placeholder={i18n.t('enterEmail')}
        inputMode="email"
        autoCompleteType="email"
        containerStyle={styles.input}
        blurOnSubmit
        onSubmitEditing={handleEditingSubmit}
        testID="signin-email-input"
        ref={emailInputRef}
      />

      <InputItem
        onChangeText={handleChange('password')}
        onBlur={handleBlur('password')}
        value={values.password}
        error={errors.password}
        touched={touched.password}
        label={i18n.t('password')}
        placeholder={i18n.t('enterPassword')}
        autoCompleteType="password"
        containerStyle={styles.input}
        secureTextEntry
        blurOnSubmit
        onSubmitEditing={handleEditingSubmit}
        testID="signin-password-input"
      />

      {!isWeb && (
        <View style={styles.switchContainer}>
          <Switch checked={values.remember} onChange={(val) => setFieldValue('remember', val)} />
          <StyledText style={[styles.switchLabel, typography.body3]}>{i18n.t('rememberMe')}</StyledText>
        </View>
      )}

      {commonError && (
        <StyledText testID="signin-error-message" style={styles.errorText}>
          {commonError}
        </StyledText>
      )}

      <View style={!isSmallMobile ? styles.buttonContainerWide : styles.buttonContainer}>
        <BaseButton
          onPress={onSubmit}
          title={i18n.t('signIn:signInButton')}
          loading={isPending}
          disabled={isPending}
          testID="signin-submit-btn"
          variant="gradient"
        />

        <TextButton
          testID="forgot-password-button"
          to={{
            route: Route.ResetPassword,
            params: { email: errors.email ? undefined : values.email },
          }}
          style={!isSmallMobile ? styles.linkInline : styles.linkBlock}
          bold={false}
        >
          {i18n.t('signIn:forgotPassword')}
        </TextButton>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  input: {
    marginBottom: 20,
  },
  switchContainer: {
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  switchLabel: {
    marginLeft: 10,
  },
  buttonContainer: {
    marginTop: 8,
  },
  buttonContainerWide: {
    marginTop: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  linkInline: {
    marginLeft: 24,
  },
  linkBlock: {
    marginTop: 24,
    ...Platform.select({
      web: {
        alignSelf: 'flex-start',
      },
      default: {
        alignSelf: 'center',
      },
    }),
  },
  errorText: {
    color: palette.red,
  },
});

export default SignInForm;
