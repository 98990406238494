import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ContentType } from 'src/constants/types';
import { ApplicationState } from 'src/state';

import { FlaggedFeature } from '../enums';
import {
  isUserAGroupAdmin,
  isUserAGroupMember,
  doesUserHaveLimitedProAccess,
  isUserAManagedGroupAdmin,
  isUserAManagedGroupMember,
  getPermissionsByContentType,
} from '../helpers';
import type { GroupInfoCurrentMember, UserPermissions } from '../types';

export const useUserInfo = () => {
  const { hasAccessToEmailPetOwnerGuides: hasAccessToEmailHandouts, ...user } = useSelector(
    (state: ApplicationState) => state.auth.user,
  );

  const {
    features,
    isPro,
    isTester,
    groupInfo,
    isSharedAccount,
    inTrial,
    hasRecurlyAccount,
    expiredSubscription,
  } = user;

  const isLimitedProUser = useMemo(() => doesUserHaveLimitedProAccess(groupInfo), [groupInfo]);
  const isAdmin = useMemo(() => isUserAGroupAdmin(groupInfo), [groupInfo]);
  const isManagedGroupAdmin = useMemo(() => isUserAManagedGroupAdmin(groupInfo), [groupInfo]);
  const isIndividual = useMemo(
    () => !isUserAGroupMember(groupInfo) && !isSharedAccount,
    [groupInfo, isSharedAccount],
  );
  const isBasicSharedAccount = useMemo(() => isSharedAccount && !isPro, [isSharedAccount, isPro]);

  const hasAccessToFlaggedFeature = useCallback(
    (feature: FlaggedFeature) => isTester && features?.includes(feature),
    [isTester, features],
  );

  const hasFullAccessToBasicContentTypes = useMemo(() => !isLimitedProUser, [isLimitedProUser]);

  const hasFullAccessToAlgorithms = useMemo(
    () => (isPro && !isLimitedProUser) || hasAccessToFlaggedFeature(FlaggedFeature.Algorithm),
    [isPro, isLimitedProUser, hasAccessToFlaggedFeature],
  );
  const canDisplayAlgorithms = useMemo(
    () => (!isLimitedProUser && !isBasicSharedAccount) || hasFullAccessToAlgorithms,
    [isLimitedProUser, hasFullAccessToAlgorithms, isBasicSharedAccount],
  );

  const hasFullAccessToClinicalHandouts = useMemo(
    () => isPro || hasAccessToFlaggedFeature(FlaggedFeature.PatientGuides),
    [isPro, hasAccessToFlaggedFeature],
  );
  const canDisplayClinicalHandouts = useMemo(
    () => (!isLimitedProUser && !isBasicSharedAccount) || hasFullAccessToClinicalHandouts,
    [isLimitedProUser, hasFullAccessToClinicalHandouts, isBasicSharedAccount],
  );

  const hasFullAccessToClinicalBriefs = useMemo(
    () => (isPro && !isLimitedProUser) || hasAccessToFlaggedFeature(FlaggedFeature.ClinicalBrief),
    [isPro, isLimitedProUser, hasAccessToFlaggedFeature],
  );

  const hasFullAccessToCoreFuncUXUpdates = useMemo(
    () => hasAccessToFlaggedFeature(FlaggedFeature.CoreFuncUXUpdates),
    [hasAccessToFlaggedFeature],
  );

  const canDisplayClinicalBriefs = useMemo(
    () => (!isLimitedProUser && !isBasicSharedAccount) || hasFullAccessToClinicalBriefs,
    [isLimitedProUser, hasFullAccessToClinicalBriefs, isBasicSharedAccount],
  );

  const hasFullAccessToDDx = isPro && !isLimitedProUser && hasAccessToFlaggedFeature(FlaggedFeature.DDx);

  const canDisplayDDx =
    ((!isLimitedProUser && !isBasicSharedAccount) || hasFullAccessToDDx) &&
    hasAccessToFlaggedFeature(FlaggedFeature.DDx);

  const hasAccessToStudentRenewals = useMemo(
    () => hasAccessToFlaggedFeature(FlaggedFeature.StudentRenewals),
    [hasAccessToFlaggedFeature],
  );

  const canManageSubscription = useMemo(
    () =>
      (isAdmin && !isManagedGroupAdmin) ||
      isIndividual ||
      !!((groupInfo as GroupInfoCurrentMember | null)?.isManagedGroup && expiredSubscription),
    [isAdmin, isManagedGroupAdmin, isIndividual, groupInfo, expiredSubscription],
  );
  const canAccessIndividualAccountFeatures = useMemo(() => !isSharedAccount, [isSharedAccount]);
  const canManageGroup = useMemo(() => isAdmin, [isAdmin]);
  const canManagePlan = useMemo(() => canManageSubscription && !inTrial, [canManageSubscription, inTrial]);

  const hasAccessToRedesignPhase1 = hasAccessToFlaggedFeature(FlaggedFeature.RedesignPhase1);
  const hasAccessToRedesignPhase2 =
    hasAccessToRedesignPhase1 && hasAccessToFlaggedFeature(FlaggedFeature.RedesignPhase2);
  const hasAccessToRedesignPhase3 =
    hasAccessToRedesignPhase2 && hasAccessToFlaggedFeature(FlaggedFeature.RedesignPhase3);
  const hasAccessToRedesignPhase4 =
    hasAccessToRedesignPhase3 && hasAccessToFlaggedFeature(FlaggedFeature.RedesignPhase4);

  const hasSupportStaffFeatureEnabled = useMemo(
    () => (groupInfo && isPro) || !!(isUserAManagedGroupMember(groupInfo) && groupInfo.isMixedGroup),
    [isPro, groupInfo],
  );

  const canSearchForUnavailableContentTypes = useMemo(() => !isLimitedProUser, [isLimitedProUser]);

  const permissions = useMemo(
    (): UserPermissions => ({
      hasFullAccessToBasicContentTypes,
      canDisplayClinicalBriefs,
      hasFullAccessToClinicalBriefs,
      hasAccessToStudentRenewals,
      canDisplayAlgorithms,
      hasFullAccessToAlgorithms,
      canDisplayClinicalHandouts,
      hasFullAccessToClinicalHandouts,
      canManageSubscription,
      canAccessIndividualAccountFeatures,
      canManageGroup,
      hasSupportStaffFeatureEnabled,
      hasFullAccessToDrugHandouts: true,
      canManagePlan,
      hasAccessToEmailHandouts,
      canSearchForUnavailableContentTypes,
      hasAccessToRedesignPhase4,
      hasFullAccessToCoreFuncUXUpdates,
      canDisplayDDx,
      hasFullAccessToDDx,
    }),
    [
      hasFullAccessToBasicContentTypes,
      canDisplayClinicalBriefs,
      hasFullAccessToClinicalBriefs,
      hasAccessToStudentRenewals,
      canDisplayAlgorithms,
      hasFullAccessToAlgorithms,
      canDisplayClinicalHandouts,
      hasFullAccessToClinicalHandouts,
      canManageSubscription,
      canAccessIndividualAccountFeatures,
      canManageGroup,
      hasSupportStaffFeatureEnabled,
      canManagePlan,
      hasAccessToEmailHandouts,
      canSearchForUnavailableContentTypes,
      hasAccessToRedesignPhase4,
      hasFullAccessToCoreFuncUXUpdates,
      canDisplayDDx,
      hasFullAccessToDDx,
    ],
  );

  const canDisplayContentType = useCallback(
    (contentType: ContentType) => permissions[getPermissionsByContentType(contentType).display],
    [permissions],
  );

  const hasAccessToContentType = useCallback(
    (contentType: ContentType) => permissions[getPermissionsByContentType(contentType).access],
    [permissions],
  );

  return {
    ...user,
    hasAccessToEmailHandouts,
    isAdmin,
    canDisplayContentType,
    hasAccessToContentType,
    permissions,
    hasRecurlyAccount,
    hasAccessToFlaggedFeature,
  };
};
